import React from 'react';
import styled from "styled-components";
import { SlArrowDown } from "react-icons/sl";
import FaqComponent from "./components/faq.component"
import { useTranslation } from 'react-i18next';
import HeaderComponent from "./page-components/header.component";
import FooterComponent from "./page-components/footer.component";
import FeaturesComponent from "./page-components/features.component";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
    }
`;

const Arrow = styled(SlArrowDown)`
    font-size: 0.6em; // adjust the size as needed
    padding-top: 2px;  // adjust padding as needed
    transition: transform 0.3s;
    transform-origin: center;
    margin-left: 5px;
    margin-top: 2px;
`;

const MenuDescription = styled.div`

`;

export const Item = styled.li`
    position: relative;
    display: inline;
    padding: 0 15px;
    font-size: 16px;
    font-weight: 500;

    &:hover {
        cursor: pointer;
    }

    &:hover div {
        display: block;
    }

    &:hover ${Arrow} {
        transform: rotate(180deg);
    }

    // ${MenuDescription} {
    //     width: 100vw;
    //     height: auto;  // Adjust the height
    //     display: none;
    //     position: absolute;
    //     background-color: #FFFFFF;
    //     min-width: 160px;
    //     padding: 12px 30px;
    //     border: 1px solid #e8eaeb;
    //     border-top: none;
    //     z-index: 1;
    //     left: 0;
    // }
`;

const Cover = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 500px; // Or set a specific height
    background-color: #ffffff;
    background-size: cover; // This will make sure your image covers the whole div
    background-repeat: no-repeat; // This will prevent the image from repeating
    background-position: center; // This will center the image

    div {
        background-image: url('https://images.unsplash.com/photo-1612838320302-4b3b3b3b3b3b');
    }

`;

interface ButtonProps {
    backgroundColor?: string;
    fontColor?: string;
    width?: string;
    height?: string;
    fontSize?: string;
}

export const GenericButton = styled.button<ButtonProps>`
    background-color: ${props => props.backgroundColor || "#cfeb34"};
    color:  ${props => props.fontColor || "#333"};
    border: none;
    padding: 10px 20px;
    width: ${props => `${props.width}px` || "auto"};
    height: ${props => `${props.height}px` || "auto"};
    font-size: ${props => `${props.fontSize}px` || "16px"};
    cursor: pointer;
    border-radius: 5px;
    & {
        transition: opacity 0.5s ease-in-out;
    }

    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }
`;

const ContentSide = styled.div`
    width: 80%;
    text-align: center;
    h1 {
        color: #303030;
        max-width: 25ch;
        font-family: Oxygen, sans-serif;
        font-size: 5em;
        font-weight: 700;
        line-height: 1.1;
    }
    p {
        text-align: center;
        color: #474747;
        font-weight: lighter;
        font-size: 1.5rem;
        line-height: 1.4;
        width: 50%;
        margin: 0 auto;
        margin-bottom: 20px;

    }
    @media (max-width: 767px) {
        h1 {
            font-size: 3em;
            max-width: 100%;
            margin-top: 20px;
        }
        p {
            font-size: 1.2rem;
            width: 100%;
    }
`;

const CoverContainer = styled.div`
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const WaitingList = styled.div`
    width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
    padding: 30px;
    @media (max-width: 767px) {
        display: none;
    }
`;

const WaitingListText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #474747;
    font-weight: lighter;
    font-size: 1.1rem;
    margin-bottom: 20px;
    padding-top: 15px;
    line-height: 1.9;
`;

const WaitingListTitle = styled.h2`
    color: #303030;
    max-width: 25ch;
    font-family: Oxygen,sans-serif;
    font-size: 2em;
    font-weight: 700;
    line-height: 1.1;
    // Add any additional styles you need
`;

const WaitingListDescription = styled.p`
    font-size: 1em;
    width: 400px;
    // Add any additional styles you need
`;

const WaitingListForm = styled.form`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const WaitingListInput = styled.input`
    padding: 10px;
    font-size: 1em;
    border: none;
    width: 350px;
    border-radius: 5px;
    height: 40px;
    margin-right: 10px; // spacing between input and button
    &:focus {
        outline: none;
    }
`;

function LandingPage() {
    const { t } = useTranslation();
    return (
        <Container>
            <HeaderComponent />
            <Cover>
                <CoverContainer>
                <ContentSide>
                    <h1>{t('welcome')}</h1>
                    <p>{t('description')}</p>
                    <GenericButton
                        backgroundColor='#3e4ae7'
                        width={'160'}
                        height={'60'}
                        fontColor='#ffffff'
                        fontSize={'18'}
                        onClick={() => window.open('https://beta-dashboard.rocketdesert.com/create-organization', '_blank')}
                    >{t('getStarted')}</GenericButton>
                </ContentSide>
                </CoverContainer>
            </Cover>
            <FeaturesComponent />
            <div>
                <FaqComponent />
            </div>
            <WaitingList>
                <WaitingListText>
                    <WaitingListTitle>{t('joinWaitList')}</WaitingListTitle>
                    <WaitingListDescription>{t('joinWaitListDescription')}</WaitingListDescription>
                </WaitingListText>
                <WaitingListForm>
                    <WaitingListInput type="text" placeholder={t('enterYourEmail')} />
                    <GenericButton
                        backgroundColor='#3e4ae7'
                        width={'160'}
                        height={'60'}
                        fontColor='#ffffff'
                        fontSize={'18'}
                    >{t('joinButton')}</GenericButton>
                </WaitingListForm>
            </WaitingList>
            <FooterComponent />
        </Container>
    );

}

export default LandingPage;
